<template>
  <el-dialog
    :title="title"
    :visible.sync="setShow"
    @close="closeEvent"
    width="30%"
  >
    <div class="information">
      <div
        v-for="(data, index) in information"
        :key="index"
        class="information-data"
      >
        <p>{{ data.label }}</p>
        <template v-if="data.label.toLowerCase() === 'status'">
          <eden-tag :value="data.value" />
        </template>
        <template v-else-if="data.label.toLowerCase() === 'services'">
          <eden-services-list :services="data.value" />
        </template>
        <span v-else>{{ data.value }}</span>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "TransactionInformation",
  props: {
    show: Boolean,
    transaction: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    setShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      return `Transaction - ${this.transaction.id}`;
    },
    information() {
      const {
        created_at,
        updated_at,
        amount,
        payment_type,
        services,
        payment_gateway,
        date_paid,
        payment_status,
      } = this.transaction;
      return [
        {
          label: "Created",
          value: this.formatDateAndTime(created_at, "ddd do, m, y"),
        },
        {
          label: "Updated",
          value: this.formatDateAndTime(updated_at, "ddd do, m, y"),
        },
        {
          label: "Paid on",
          value: this.formatDateAndTime(date_paid, "ddd do, m, y"),
        },
        {
          label: "Status",
          value: this.formatText(payment_status),
        },
        {
          label: "Amount",
          value: this.formatPrice(amount),
        },
        {
          label: "Services",
          value: services,
        },
        {
          label: "Payment Gateway",
          value: this.formatPaymentGateway(payment_gateway),
        },
        {
          label: "Payment Type",
          value: this.formatText(payment_type),
        },
      ];
    },
  },
  methods: {
    closeEvent() {
      this.setShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.information {
  display: flex;
  flex-wrap: wrap;

  &-data {
    width: 50%;
    margin-bottom: 20px;

    p {
      color: var(--eden-grey-tertiary);
      margin-bottom: 1px;
      font-size: 0.75rem;
    }

    span {
      color: var(--eden-grey-primary);
      font-size: 0.825rem;
    }
  }
}
</style>
